import { render, staticRenderFns } from "./ModulesCarousel.vue?vue&type=template&id=5fdd3c2e"
import script from "./ModulesCarousel.vue?vue&type=script&lang=js"
export * from "./ModulesCarousel.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SnippetCarousel: require('/var/www/vhosts/anothercodeproject.eu/jgast.anothercodeproject.eu/components/snippets/SnippetCarousel.vue').default})
