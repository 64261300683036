import { render, staticRenderFns } from "./LayoutHeader.vue?vue&type=template&id=ce5ac872&scoped=true"
import script from "./LayoutHeader.vue?vue&type=script&lang=js"
export * from "./LayoutHeader.vue?vue&type=script&lang=js"
import style0 from "./LayoutHeader.vue?vue&type=style&index=0&id=ce5ac872&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ce5ac872",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SnippetsEmphasizedNavButtons: require('/var/www/vhosts/anothercodeproject.eu/jgast.anothercodeproject.eu/components/snippets/SnippetsEmphasizedNavButtons.vue').default,SnippetMenu: require('/var/www/vhosts/anothercodeproject.eu/jgast.anothercodeproject.eu/components/snippets/SnippetMenu.vue').default,LayoutLogo: require('/var/www/vhosts/anothercodeproject.eu/jgast.anothercodeproject.eu/components/layout/LayoutLogo.vue').default,LayoutControlNav: require('/var/www/vhosts/anothercodeproject.eu/jgast.anothercodeproject.eu/components/layout/LayoutControlNav.vue').default})
