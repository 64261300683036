const plugin = require('tailwindcss/plugin')

const pageTransition = 800

module.exports = {
  mode: 'jit',

  safelist: [
    'delay-50', 'delay-100', 'delay-150', 'delay-200', 'delay-250', 'delay-300',
    'delay-350', 'delay-400', 'delay-450', 'delay-500', 'delay-550', 'delay-600',
    'delay-650', 'delay-700', 'delay-750', 'delay-800', 'delay-850', 'delay-900',
    'delay-950', 'delay-1000',
    'bg-blue', 'bg-orange', 'bg-red', 'bg-green', 'bg-white', 'bg-black',
    'text-white', 'text-black',
    'col-span-1', 'col-span-2',
    'row-start-1', 'row-start-2', 'row-start-3',
    'col-start-1', 'col-start-2',
    'border-black', 'border-white',
  ],

  variants: {
    extend: {
      opacity: ['group-hover'],
      rotate: ['hover'],
      margin: ['hover'],
      transitionTimingFunction: ['hover'],
      cursor: ['hover'],
      mixBlendMode: ['hover']
    }
  },

  theme: {
    screens: {
      xs: {'max': '480px'},
      sm: {'max': '640px'},
      'sm+': {'max': '800px'},
      md: {'max': '1000px'},
      lg: {'max': '1400px'},
      portrait: { raw: '(orientation: portrait)' },
      landscape: { raw: '(orientation: landscape)' },
      'hover-hover': {'raw': '(hover: hover)'}
    },
    transitionDuration: {
      'hover': '200ms',
      'page': `${pageTransition}ms`,
      'image': `400ms`,
      'notification': `${pageTransition}ms`,
      'showMore': `${pageTransition}ms`,
      'nav': `600ms`,
      'form': `${pageTransition}ms`,
      'carousel': `${pageTransition}ms`,
      'formStep': `${pageTransition}ms`,
      'controls': `${pageTransition}ms`,
      'fadeInOut': '1200ms'
    },
    extend: {
      rotate: {
        '72': '72deg',
        '360': '360deg'
      },
      minHeight: {
        'step-desktop': 'var(--inner-height)',
        'step-mobile': 'var(--inner-height)',
        'fh-3/4': 'calc(var(--frame-height) / 4 * 3)',
      },
      lineHeight: {
        'self': '1em',
        'icon': '0.8rem'
      },
      boxShadow: {
        'figma': '0px 4px 30px rgba(0, 0, 0, 0.2)',
        'lg': '0px 0.8px 2.7px rgba(0, 0, 0, 0.037), 0px 2.1px 6.9px rgba(0, 0, 0, 0.053), 0px 4.3px 14.2px rgba(0, 0, 0, 0.067), 0px 8.8px 29.2px rgba(0, 0, 0, 0.083), 0px 24px 80px rgba(0, 0, 0, 0.12)'
      },
      zIndex: {
        '75': 75,
        '100': 100
      },
      borderRadius: {
        DEFAULT: '6px',
        'xl': '6.25rem',
        'large': '16px',
        'full': '50%'
      },
      maxWidth: {
        'tiny': '8rem',
        'frame-ex': 'calc(var(--frame-width) + 10rem)',
        'frame-md': 'calc(var(--frame-width) - 4rem)',
        'frame-sm': 'calc(var(--frame-width) - 10rem)',
        'frame': 'var(--frame-width)',
        'content-single': 'var(--content-single)',
        'content-double': 'var(--content-double)'
      },
      flex: {
        'solid': '0 0  auto'
      },
      spacing: {
        'half': '50%',
        'full-2x': '200%',
        'full-4x': '400%',
        'fh-full': 'var(--frame-height)',
        'fw-full': 'var(--frame-width)',
        'fw-outside': 'calc((100vw - var(--frame-width)) / 2)',
        'fh-3/4': 'calc(var(--frame-height) / 4 * 3)',
        'fw-3/4': 'calc(100vh * (3 / 4))', // 'calc(var(--frame-width) * (3 / 4))',
        'fw-4/3': 'calc(100vh * (4 / 3))',// 'calc(var(--frame-width) * (4 / 3))',
        'fw-3/4-4': 'calc(100vh * (3 / 4) - 2rem)', // 'calc(var(--frame-width) * (3 / 4) - 2rem)',
        'fw-4/3-4': 'calc(100vh * (4 / 3) - 2rem)', // 'calc(var(--frame-width) * (4 / 3) - 2rem)',
        'hero': 'calc(100vh - 0rem)',
        'f1': 'calc(100vh - 2rem)',
        'f2': 'min(100vw, 50rem)',
        '125': '125%',
        'full-4': 'calc(100% - 2rem)',
        'button': 'calc(0.8rem + 1.5rem)', // 46px,
        'button-loading': '3.05rem',
        'portrait': '150%',
        'landscape': '66.67%',
      },
      colors: {
        blue: {
          DEFAULT: '#135AFB'
        },
        red: {
          DEFAULT: '#FC4640'
        },
        orange: {
          DEFAULT: '#C85A00'
        },
        green: {
          DEFAULT: '#83F26A'
        },
        black: {
          DEFAULT: '#0E0E0E',
          deep: '#000000',
          opaque: 'rgba(0,0,0,0.3)'
        },
        gray: {
          DEFAULT: '#CCCCCC',
          medium: '#8f8f8f',
          dark: '#505050',
          contrast: 'rgb(115, 115, 115)'
        },
        white: {
          DEFAULT: '#FAFAFA',
          deep: '#ffffff',
          opaque: 'rgba(255,255,255,0.1)'
        }
      },
      fontSize: {
        'sm': ['0.6rem', { // 12
          letterSpacing: '0',
          lineHeight: '1.25'
        }],
        'sm-mobile': ['0.75rem', { // 12
          letterSpacing: '0',
          lineHeight: '1.25'
        }],
        'base': ['1rem', {  // 16
          letterSpacing: '0',
          lineHeight: '1.25'
        }],
        'md': ['1.25rem', { // 20
          letterSpacing: '0',
          lineHeight: '1.15'
        }],
        'lg': ['1.875rem', { // 30
          letterSpacing: '0',
          lineHeight: '1.1'
        }],
        'xl': ['3rem', { // 48
          letterSpacing: '0',
          lineHeight: '1',
        }]
      },
      animation: {
       'fade-in': `fadeIn 800ms ease 1 forwards`,
       'fade-in-delay': `fadeIn 800ms ease 800ms 1 forwards`,
       'fade-out': 'fadeOut 800ms ease 1 forwards',
       'fade-in-out': 'fadeInOut 1200ms ease 1 forwards',
       'fade-in-out-loop': 'fadeInOutLoop 4000ms ease infinite forwards',
       'spin': 'spin 1600ms ease infinite forwards',
       'rotateForth': 'rotateForth 400ms ease 1 forwards',
       'rotateBack': 'rotateBack 400ms ease 1 forwards',
       'marquee': 'marquee 4000ms linear infinite forwards',
       'translate-in-bot': 'translateInBot 600ms ease 1 forwards',

       'page-in': `pageTranslateMobileIn ${pageTransition}ms ease 200ms 1 forwards`,
       'page-out': `pageOpacityOut ${pageTransition}ms ease 1 forwards`,

      'page-in-spaced': `pageTranslateIn ${pageTransition}ms ease 200ms 1 forwards`,
      'page-out-spaced': `pageTranslateOut ${pageTransition}ms ease 1 forwards`,

       'page-in-top': `pageTranslateInTop ${pageTransition}ms ease 1 forwards`,
       'page-in-blur': `pageInBlur ${pageTransition}ms ease 1 forwards`,
       'page-out-blur': `pageOutBlur ${pageTransition}ms ease 1 forwards`,
       'page-opacity-in': `pageOpacityIn ${pageTransition}ms ease 1 forwards`,
       'page-opacity-out': `pageOpacityOut ${pageTransition}ms ease 1 forwards`,
       'nav-in': `pageOpacityIn ${pageTransition / 2}ms ease 1 forwards`,
       'nav-out': `pageOpacityOut ${pageTransition / 2}ms ease 1 forwards`,
       'nav-in-blur': `pageInBlur ${pageTransition / 2}ms ease 1 forwards`,
       'nav-out-blur': `pageOutBlur ${pageTransition / 2}ms ease 1 forwards`,
      },
      keyframes: {
        translateInBot: {
          '0%': {
            tranform: 'translateY(200%)'
          },
          '100%': {
            transform: 'translateY(0)'
          }
        },
        fadeIn: {
          '0%': { opacity: '0' },
          '100%': { opacity: '1'}
        },
        fadeOut: {
          '0%': { opacity: '1'},
          '100%': { opacity: '0' }
        },
        fadeInOut: {
          '0%': {
            opacity: '0',
            // transform: 'scale(1.00)'
          },
          '40%': { /* transform: 'scale(1.05)' */ },
          '50%': { opacity: '1' },
          '40%': { /* transform: 'scale(1.05)' */ },
          '100%': {
            opacity: '0',
            // transform: 'scale(1.00)'
          }
        },
        fadeInOutLoop: {
          '0%': {
            opacity: '0',
            // transform: 'scale(1.00)'
          },
          '20%': { opacity: '1' },
          '80%': { opacity: '1' },
          '100%': {
            opacity: '0',
            // transform: 'scale(1.00)'
          }
        },
        marquee: {
          '0%': { transform: 'translate3d(0, 0, 0)' },
          '100%': { transform: 'translate3d(-50%, 0, 0)' }
        },
        rotateForth: {
          '0%': { transform: 'rotate(0deg)'},
          '100%': { transform: 'rotate(180deg)'},
        },
        rotateBack: {
          '0%': { transform: 'rotate(180deg)'},
          '100%': { transform: 'rotate(0deg)'},
        },
        spin: {
          '0%': { transform: 'rotate(0deg)'},
          '100%': { transform: 'rotate(360deg)'},
        },
        strokeDasharray: {
          '0%': {
            'stroke-dasharray': '0, 100'
          },
          '100%': {
            'stroke-dasharray': '100, 100'
          }
        },
        pageOpacityIn: {
          '0%': {
            opacity: '0',
            // filter: 'blur(24px)'
          },
          '100%': {
            opacity: '1',
            // filter: 'blur(0)'
          }
        },
        pageOpacityOut: {
          '0%': {
            opacity: '1',
            // filter: 'blur(0)'
          },
          '100%': {
            opacity: '0',
            // filter: 'blur(24px)'
          }
        },
        pageTranslateMobileIn: {
          '0%': {
            transform: 'translate3d(0, 125vh, 0)'
          },
          '100%': {
            transform: 'translate3d(0, 0, 0)'
          }
        },
        pageTranslateMobileOut: {
          '0%': {
            transform: 'translate3d(0, 0, 0)'
          },
          '100%': {
            transform: 'translate3d(0, -125vh, 0)'
          }
        },

        pageTranslateIn: {
          '0%': {
            transform: 'translate3d(-50%, 125%, 0)'
          },
          '100%': {
            transform: 'translate3d(-50%, 0, 0)'
          }
        },
        pageTranslateOut: {
          '0%': {
            transform: 'translate3d(-50%, 0, 0)'
          },
          '100%': {
            transform: 'translate3d(-50%, -125%, 0)'
          }
        },
        pageTranslateInTop: {
          '0%': {
            transform: 'translate3d(-50%, -125%, 0)'
          },
          '100%': {
            transform: 'translate3d(-50%, 0, 0)'
          }
        },
        pageInBlur: {
          '0%': {
            opacity: '0',
            filter: 'blur(24px)'
          },
          '100%': {
            opacity: '1',
            filter: 'blur(0)'
          }
        },
        pageOutBlur: {
          '0%': {
            opacity: '1',
            filter: 'blur(0)'
          },
          '100%': {
            opacity: '0',
            filter: 'blur(24px)'
          }
        },
      },
      transitionProperty: {
        'width': 'width',
        'filter': 'filter'
      },
      transitionDelay: {
         '0': '0ms',
         '50': '50ms',
         '100': '100ms',
         '150': '150ms',
         '200': '200ms',
         '250': '250ms',
         '300': '300ms',
         '350': '350ms',
         '400': '400ms',
         '450': '450ms',
         '500': '500ms',
         '550': '550ms',
         '600': '600ms',
         '650': '650ms',
         '700': '700ms',
         '750': '750ms',
         '800': '800ms',
         '850': '850ms',
         '900': '900ms',
         '950': '950ms',
         '1000': '1000ms',
      }
    }
  },
  plugins: [
    plugin(function({ addBase, theme }) {
      addBase({
        'html': {
          '-moz-osx-font-smoothing': 'grayscale',
          '-webkit-font-smoothing': 'antialiased',
        },
        'body': {
          'font-family': '"ABC Monument Grotesk", "Helvetica", sans-serif',
        },
      })
    })
  ]
}
