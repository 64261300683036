
import continuousSlider from '@/mixins/continuousSlider'
import marked from '@/mixins/marked'

export default {
  props: {
    headlines: Array,
    hasCTASettings: {
      type: Boolean,
      default: false,
    },
    hasCTAModule: {
      type: Boolean,
      default: false,
    },
    ctaText: String,
    ctaLink: String,
  },

  data() {
    return {
      continuousSliderTimeout: 4000,
    }
  },

  mixins: [marked, continuousSlider],
}
