var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('BasicLink',{staticClass:"px-4 sm:px-1.5 py-1.5 xs:text-sm-mobile leading-none whitespace-nowrap w-full text-md relative border border-white text-white hover:bg-transparent",attrs:{"item":{
      content: {
        linkType: 'intern',
        linkIntern: '8fdaf9ac-0cc9-4b38-a40b-e6d64c51f0a0',
        text: _vm.$t('layout.bookAppointment'),
      },
    },"hasNoArrow":true}}),_vm._v(" "),_c('BasicLink',{staticClass:"px-4 sm:px-1.5 py-1.5 xs:text-sm-mobile leading-none whitespace-nowrap w-full text-md relative bg-white",attrs:{"item":{
      content: {
        linkType: 'intern',
        linkIntern: '0998e2df-9db1-43a8-87b1-c6bd3396c638',
        text: _vm.$t('layout.inquire'),
      },
    },"hasNoArrow":true}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }