
import pageTransition from '@/mixins/pageTransition'
import _throttle from 'lodash.throttle'
import _debounce from 'lodash.debounce'
import { gsap } from 'gsap'
import isInView from '@/mixins/isInView'

export default {
  props: {
    items: Array,
    startAt: Number,
    startWithInView: Boolean,
    isNavigationHidden: Boolean,
    isPortrait: Boolean,
    pushSlideTo: Number,
  },

  mixins: [pageTransition, isInView],

  data() {
    return {
      isRunning: false,
      previousSlide: 0,
      currentSlide: 0,

      visibilityMatrix: [], // responsible for if a slide is visible
      progressMatrix: [], // responsible for how much a slide already run
      timingMatrix: [], // responsible for how long a slide will run
      tweenMatrix: [], // responsible for tween objects
      vimeoMatrix: [], // responsible for vimeo videos
      loadingMatrix: [], // responsible for vimeo loading
      loadedImageMatrix: [], // responsible for ready images to display

      touch: {
        startX: 0,
        startY: 0,
        direction: null,
        isNavigating: true,
      },

      vimeoIsPausable: false,
      vimeoPauseOnNextTick: false,

      swipeDirection: '', // Holds the swipe direction
      touchStartX: 0, // Records the starting X coordinate of the touch
      touchEndX: 0, // Records the ending X coordinate of the touch
    }
  },

  watch: {
    async currentSlide(value) {
      /*
       * Visibility
       */
      this.visibilityMatrix = this.visibilityMatrix.map((i) => false)
      this.visibilityMatrix[value - 1] = true

      if (!this.isRunning) return

      /*
       * Progres no Tween
       */
      // if (!this.startWithInview && this.isFrameVisible) this.startStop()

      /*
       * Progress / Tween
       */
      // if (this.startWithInView || !this.isFrameVisible) this.startStopContinuous(true)
      if (this.startWithInView) this.startStopContinuous(true)
    },
    async isInView(val) {
      if (this.startWithInView) {
        this.isRunning = val
        this.startStopContinuous(val)
      }
    },
    progressMatrix: {
      deep: true,
      handler(val) {
        if (val.length > 0 && val[this.index].val === 100) this.slideNext()
      },
    },
    pushSlideTo(val) {
      this.slideTo(val)
    },
    isFrameVisible(val) {
      if (val) {
        this.isRunning = false
        // this.vimeoStop(this.index)
        // this.tweenStop(this.index)
        this.vimeoReset(this.index)
        this.tweenReset(this.index)
      } else {
        this.isRunning = true
        // this.startStopContinuous(true)
        this.startStop()
      }
    },
  },

  computed: {
    index() {
      return this.currentSlide - 1
    },
    prevIndex() {
      return this.previousSlide - 1
    },
    isFrameVisible() {
      return this.$store.state.layout.isFrameVisible
    },
  },

  methods: {
    /*
     * Main
     */
    async startStop(boolean) {
      const item = this.items[this.index]
      if (this.previousSlide) {
        // this.vimeoStop(this.prevIndex)
        this.vimeoReset(this.prevIndex)
      }
      if (item.content && item.content.vimeoUrl) {
        this.vimeoStart(this.index)
      }
    },

    async startStopContinuous(boolean) {
      const item = this.items[this.index]

      if (boolean) {
        if (this.previousSlide) {
          this.vimeoReset(this.prevIndex)
          this.tweenReset(this.prevIndex)
        }
        if (item.content && item.content.vimeoUrl) {
          this.vimeoStart(this.index)
          this.tweenStart(this.index)
        } else {
          this.tweenStart(this.index)
        }

        if (this.isFrameVisible)
          this.$store.commit('carousel/setInViewFilename', item.filename)
      } else {
        this.vimeoStop(this.index)
        this.tweenStop(this.index)
      }
    },

    /*
     * Tween
     */
    tweenStart(i) {
      const timing =
        this.timingMatrix[i] * ((100 - this.progressMatrix[i].val) / 100) // calculate rest timing
      this.tweenMatrix[i] = gsap.to(this.progressMatrix[i], {
        duration: timing,
        val: 100,
        ease: 'linear',
      })
    },

    tweenStop(i) {
      const tween = this.tweenMatrix[i]
      if (tween) tween.pause()
    },

    tweenReset(i) {
      const tween = this.tweenMatrix[i]
      if (tween) {
        this.tweenMatrix[i].kill()
        this.progressMatrix[i].val = 0
      }
    },

    /*
     * Vimeo
     */
    vimeoStart(i) {
      // console.log('V Start', i)
      this.$set(this.vimeoMatrix, i, 'play')
    },
    vimeoStop(i) {
      // console.log('V Stop', i)
      this.$set(this.vimeoMatrix, i, 'pause')
    },
    vimeoReset(i) {
      // console.log('V Reset', i)
      this.$set(this.vimeoMatrix, i, 'pause')
      setTimeout(() => {
        this.$set(this.vimeoMatrix, i, 'reset')
      }, 400)
    },

    /*
     * Slider
     */
    /*
     * Always requires an actions = is Running = true
     */
    async slideTo(index) {
      await this.$nextTick()
      await this.$nextDOMUpdate()
      this.isRunning = true
      this.previousSlide = this.currentSlide
      this.currentSlide = index + 1
      this.scrollPagination()
    },
    slideNext() {
      this.previousSlide = this.currentSlide
      if (this.currentSlide === this.items.length) {
        this.currentSlide = 1
      } else {
        this.currentSlide++
      }
      this.scrollPagination()
    },
    slidePrev() {
      this.previousSlide = this.currentSlide
      if (this.currentSlide === 1) {
        this.currentSlide = this.items.length
      } else {
        this.currentSlide--
      }
      this.scrollPagination()
    },
    visible(index) {
      return this.visibilityMatrix[index]
    },
    loadedImage(index) {
      return this.loadedImageMatrix[index]
    },
    scrollPagination() {
      this.$refs.pagination.scrollTo({
        left:
          this.$refs['pagination-item'][this.currentSlide - 1].offsetLeft -
          this.$refs.pagination.clientWidth / 2 +
          this.$refs['pagination-item'][this.currentSlide - 1].clientWidth / 2,
        behavior: 'smooth',
      })
    },
    willBeVisible(index) {
      const minusRange = index - 1 < 0 ? this.items.length - 1 : index - 1
      const plusRange = index + 1 === this.items.length ? 0 : index + 1
      return (
        this.visibilityMatrix[index] ||
        this.visibilityMatrix[minusRange] ||
        this.visibilityMatrix[plusRange]
      )
    },
    makeMatrix() {
      this.visibilityMatrix = this.items.map((i) => false)
      this.progressMatrix = this.items.map((i) => {
        return {
          val: 0,
          tween: null,
        }
      })
      this.timingMatrix = this.items.map((i) =>
        i.content && i.content.vimeoUrl ? 12 : 6
      )
      this.tweenMatrix = this.items.map((i) => null)
      this.vimeoMatrix = this.items.map((i) => '')
      this.loadingMatrix = this.items.map((i) => false)
      this.loadedImageMatrix = this.items.map((i) => false)
    },

    startRunningIfNot() {
      if (!this.isRunning) this.isRunning = true
    },

    /*
     * Init
     */
    init() {
      this.makeMatrix()
      this.currentSlide = this.startAt ? this.startAt : 1
    },

    /*
     * Swipe
     */
    onTouchStart(event) {
      // Record the starting X coordinate
      this.touchStartX = event.changedTouches[0].clientX
    },
    onTouchEnd(event) {
      // Record the ending X coordinate
      this.touchEndX = event.changedTouches[0].clientX
      this.handleSwipe()
    },
    handleSwipe() {
      const deltaX = this.touchEndX - this.touchStartX
      const threshold = 50 // Minimum distance for a swipe to be considered valid

      if (deltaX > threshold) {
        this.swipeDirection = 'right'
        this.slidePrev()
      } else if (deltaX < -threshold) {
        this.swipeDirection = 'left'
        this.slideNext()
      } else {
        this.swipeDirection = '' // Not a valid swipe
      }
    },
  },

  mounted() {
    this.init()
  },
}
